.alert {
  @include ff-montserrat-reg;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  background: red;
  text-align: center;
  margin: 5px auto;
}

.is-invalid {
  box-shadow: 0 0 0 1pt red;
}

.invalid-message {
  @include ff-montserrat-bold;
  color: red;
  font-size: 12px;
}

.message-area {
  height: 10px;
}

.brcAlert {
  .feather {
    display: none !important;
  }
}
