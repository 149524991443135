//
// Type
// --------------------------------------------------

h1 {
  font-size: 55px;

  @include responsive(large) {
    font-size: 42px;
  }

  @include responsive(small) {
    font-size: 30px;
  }
}

h2 {
  font-size: 38px;

  @include responsive(large) {
    font-size: 32px;
  }

  @include responsive(medium) {
    font-size: 30px;
  }

  @include responsive(small) {
    font-size: 26px;
  }
}

h3 {
  font-size: 24px;

  @include responsive(large) {
    font-size: 22px;
  }

  @include responsive(medium) {
    font-size: 20px;
  }

  @include responsive(small) {
    font-size: 18px;
  }
}

h4 {
  font-size: 22px;

  @include responsive(large) {
    font-size: 21px;
  }

  @include responsive(medium) {
    font-size: 20px;
  }

  @include responsive(small) {
    font-size: 19px;
  }
}

h5 {
  font-size: 18px;

  @include responsive(large) {
    font-size: 17px;
  }

  @include responsive(medium) {
    font-size: 16px;
  }

  @include responsive(small) {
    font-size: 15px;
  }
}

h6 {
  font-size: 13px;
}

ul,
ol {
  margin-top: 0;
  padding: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}
