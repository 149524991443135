// Brand colors (optional)
// -------------------------
$primary-background-blue: #1c2b39;
$darker-blue: #262829;
$dark-blue: #131e27;
$light-blue: #b3e1ea;
$light-blue-on-white: #1c2b39; 
$light-blue-button: #b3e1ea;
$light-blue-button-disabled: rgba(179, 225, 234, 0.5);
$active-green: #3c9e87;
$active-blue: #16ACC0;
$active-blue-secondary: #8bd1df;
$light-gray: #32404c;
$light-gray-blue: #32404c;
$red: #b2282d;
$second-red: #b2282d;
$light-red: rgb(197, 54, 59);
$green: #72B870;
$status-grey: #7E868C;
$light-status-gray: #CED0D2;
$orange: #ef7a32;
$white: #fff;
$purple: #C76FDA;
$second-light-gray: #525B60;
$third-light-gray: #5f6a70;

$client-header-height: 105px;
$client-sidebar-width: 80px;

// Typography
// -------------------------
$open-sans-font-family: 'Open Sans', sans-serif;

// Retina/HiDPI screen - used w/in the retinize mixin
// -------------------------
$is-hidpi: "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx)";

$material-palette: (
        50 : #e0f1f5,
        100 : #b3dce6,
        200 : #80c5d5,
        300 : #4daec4,
        400 : #269cb8,
        500 : #3C9E87,
        600 : #0083a4,
        700 : #00789a,
        800 : #006e91,
        900 : #005b80,
        A100 : #ade3ff,
        A200 : #7ad2ff,
        A400 : #47c0ff,
        A700 : #2eb7ff,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);
