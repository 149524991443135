@import "~quill/dist/quill.snow.css";

@import "./assets/scss/base/variables.scss";
@import "./assets/scss/base/mixins.scss";
@import "./assets/scss/base/normalize.scss";
@import "./assets/scss/base/reset.scss";
@import "./assets/scss/base/type.scss";
@import "./assets/scss/base/print.scss";
@import "./assets/scss/base/global.scss";
@import "./assets/scss/material";

//@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://use.typekit.net/lvp8exm.css);

.ql-toolbar {
    background: #f9f9f9;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #3C9E87;
}

.ql-picker-options .ql-picker-item:before {
    content: attr(data-value);
}

.ql-lbvar .ql-picker-label:before {
    content: "Variables";
    margin-right: 18px;
}

.ql-container.ql-container {
    @include ff-montserrat-reg;
    font-weight: normal;
    font-size: 18px;

    strong,
    h1,
    h2 {
        @include ff-montserrat-bold;
        font-weight: normal;
    }

    a {
        color: rgb(0, 0, 238);
        @include ff-montserrat-reg;
        font-weight: normal;
        text-align: left;
        text-decoration: none;
    }

    h1 {
        font-size: 28px !important;
    }
    h2 {
        font-size: 22px !important;
    }
}

lb-template-var {
    color: #3C9E87;
    font-weight: bold;
}

body {
    background: $primary-background-blue;
}

// Modal overrides
.nsm-dialog.nsm-dialog-open {
    position: fixed;
    max-width: 90%;
    height: calc(100vh - 15%);
}

.mat-dialog-container {
    border-radius: 6px !important;
    padding: 30px 30px 18px 30px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cdk-global-overlay-wrapper {
    overflow: scroll;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.588);
}

.ng-star-inserted {
    h1 {
        font-size: 22px !important;
    }
}

.loading-shade {
    text-align: center;

    svg {
        animation: spin 2s infinite linear;
    }
}

.scrollbar::-webkit-scrollbar,
.mat-dialog-container::-webkit-scrollbar {
    width: 5px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollbar::-webkit-scrollbar *,
.mat-dialog-container::-webkit-scrollbar * {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb,
.mat-dialog-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar-jobs::-webkit-scrollbar {
    width: 5px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scrollbar-jobs::-webkit-scrollbar * {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar-jobs::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.horizontal-scrollbar::-webkit-scrollbar {
    // width: 5px;
    height: 5px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.horizontal-scrollbar::-webkit-scrollbar * {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.candidate_details_page {
    padding-top: 50px;
    height: 100%;
    @include responsive(mobile) {
        display: block;
        width: 95% !important;
        margin: 0 auto;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .mat-dialog-container {
        margin-bottom: 100px !important;

        @include responsive(mobile) {
            margin-bottom: 0px !important;
        }
    }
}

.mobileContainer {
    .mat-dialog-container {
        font {
            @include ff-montserrat-bold;
            color: #b3e1ea !important;
            font-weight: bold;
        }
        height: 100%;
        margin: 100px 0px;

        @include responsive(mobile) {
            margin: 0px;
        }
    }
}

.candidate_details_page {
    @include responsive(mobile) {
        max-width: 95% !important;

        .mat-dialog-container {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }
}

.about-section .copy {
    ol,
    ul {
        padding-left: 15px;
    }
}

.details-footer .legal,
app-home .legal {
    a,
    a:hover,
    a:visited,
    a:active {
        color: #7e7e7e;
        text-decoration: underline;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cdk-global-scrollblock {
    top: 0px !important;
}

.cdk-overlay-pane {
    @include responsive(mobile) {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100% !important;
        height: 100% !important;

        .mat-dialog-container {
            border-radius: 0px !important;
            padding: 0px !important;

            .header,
            .modal-header {
                padding: 70px 15px 10px 15px !important;
                box-shadow: 0 -3px 9px 0 rgba(26, 33, 38, 0.9);
                width: calc(100% - 30px);
            }

            .modal-header {
                margin-bottom: 0px !important;
            }

            .mat-dialog-content {
                padding: 10px 15px !important;
            }
        }
    }
}

.forgot-password-dialog {
    min-width: 400px;
}

.extendDialog {
    @include responsive(mobile) {
        .header {
            position: absolute;
            background: #ffffff;
            z-index: 1000000;
            padding: 30px 25px 10px 25px !important;
        }

        .mat-dialog-container {
            .mat-dialog-content {
                padding: 200px 25px 10px 25px !important;
            }
        }
    }
}

.messageContainer {
    h1[mat-dialog-title].mat-dialog-title {
        color: rgb(38, 40, 41);
        @include ff-montserrat-bold;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 36px;
    }

    .mat-dialog-container {
        @include responsive(mobile) {
            padding: 75px 20px 20px 20px !important;
        }
    }
}

.mat-snack-bar-container {
    color: #000;
    @include ff-montserrat-reg;
    background: #fff;

    .mat-button {
        @include ff-montserrat-bold;
        color: #b2282d;
        text-transform: uppercase;
    }
}
