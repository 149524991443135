//
// Mixins
// --------------------------------------------------
// UTILITY MIXINS
// --------------------------------------------------
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@mixin cf {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin absolute-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@mixin reset-list-style {
    list-style: none;
    margin: 0;
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Webkit-style focus
// ------------------
@mixin tab-focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

// Placeholder text
// -------------------------
@mixin placeholder($color: $placeholder-text) {
    & :-moz-placeholder {
        color: $color;
    }
    &:-ms-input-placeholder {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color: $color;
    }
}

// FONTS
// --------------------------------------------------
@mixin font-family-open-sans-serif {
    font-family: $open-sans-font-family;
}

@mixin paragraph-large {
    font-size: 18px;
    line-height: 26px;
    @include responsive(medium) {
        font-size: 15px;
    }
}

@mixin paragraph-standard {
    font-size: 15px;
    line-height: 25px;
}

@mixin paragraph-small {
    font-size: 13px;
    line-height: 22px;
}

@mixin quote-text {
    font-size: 22px;
    line-height: 32px;
    font-style: italic;
    @include responsive(small) {
        font-size: 20px;
    }
}

// RETINA
// --------------------------------------------------
//
@mixin retinize($file, $type, $width, $height) {
    background-repeat: no-repeat;
    background-image: url("../imgs/"+$file+"."+$type);
    background-size: $width $height;
    @media #{$is-hidpi} {
        & {
            background-image: url("../imgs/"+$file+"-2x."+$type);
        }
    }
}

// Breakpoints
// --------------------------------------------------
$mobile: 823px;
$medium: 960px;
$large: 1044px;
$xlarge: 1280px;
@mixin responsive($width) {
    @if $width==mobile {
        @media screen and (max-width: $mobile) {
            @content;
        }
    } @else if $width==mobile-landscape {
        @media screen and (max-width: $mobile) and (orientation: landscape) {
            @content;
        }
    } @else if $width==mobile-portrait {
        @media screen and (max-width: $mobile) and (orientation: portrait) {
            @content;
        }
    } @else if $width==medium {
        @media screen and (max-width: $large) {
            @content;
        }
    } @else if $width==large {
        @media screen and (max-width: $xlarge) {
            @content;
        }
    } @else if $width==xlarge {
        @media screen and (min-width: $xlarge) {
            @content;
        }
    }
}

// Fonts
// --------------------------------------------------
@mixin ff-montserrat-reg {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

@mixin ff-montserrat-semibold {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
}

@mixin ff-montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
}

@mixin ff-freight-text-pro-300-reg {
    font-family: "freight-text-pro", serif !important;
    font-weight: 300;
}

@mixin ff-freight-text-pro-500-reg {
    font-family: "freight-text-pro", serif !important;
    font-weight: 500;
}

@mixin ff-freight-text-pro-700-reg {
    font-family: "freight-text-pro", serif !important;
    font-weight: 700;
}

@mixin ff-freight-text-pro-900-reg {
    font-family: "freight-text-pro", serif !important;
    font-weight: 900;
}

@mixin blue-button {
    @include ff-montserrat-bold;
    text-align: center;
    margin: 25px auto 0px auto;
    display: block;
    background: $light-blue-button;
    border: none;
    color: black;
    padding: 10px;
    font-size: 12px;
    border-radius: 4px;
    width: 150px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        background: $active-blue-secondary;
        transition: background-color .2s ease-in-out;
    }
    &:disabled {
        background: $light-blue-button-disabled;
        color: rgba(39, 51, 57, 0.5);
        box-shadow: none;

        &:active {
            background: $light-blue-button-disabled;
            color: rgba(39, 51, 57, 0.5);
            box-shadow: none;
        }

        &:hover {
            box-shadow: none;
        }
    }
    &:active {
        background: $active-blue;
    }
}

@mixin form-card($width: 600px) {
    $padding: 25px;
    width: $width;
    max-width: calc(90% - #{$padding * 2});
    background: $white;
    margin: 25px auto;
    padding: $padding;
    box-shadow: 0 4px 20px 0 rgba(26, 33, 38, 0.5);
    border-radius: 4px;
    h1 {
        @include ff-montserrat-bold;
        font-size: 36px;
        margin: 5px 0 15px;
    }
    p {
        @include ff-montserrat-reg;
        margin: 5px 0 15px;
    }
    button {
        @include blue-button;
    }
}

@mixin dashboard-view-all-btn {
    @include ff-montserrat-bold;
    float: right;
    text-align: center;
    background: $light-blue-button;
    border: none;
    color: black;
    padding: 10px;
    font-size: 12px;
    border-radius: 4px;
    width: 130px;
    text-transform: uppercase;

    &:active {
        background: #16acc0;
    }

    &:hover {
        background: $active-blue-secondary;
        transition: background-color .2s ease-in-out;
    }

    @include responsive(large) {
        width: 90px;
        font-size: 12px;
    }
}

@mixin dashboard-view-all-btn-inactive {
    @include ff-montserrat-bold;
    float: right;
    text-align: center;
    background: #32404c;
    border: none;
    color: white;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    width: 130px;
    opacity: 0.5;
    text-transform: uppercase;
    @include responsive(large) {
        width: 90px;
    }
    @media screen and (min-width: 801px) and (max-width: 1000px) {
        float: none;
        display: block;
        margin: 5px 0px 0px 0px;
    }
}

@mixin spin($spin-time: 2s) {
    animation: spin $spin-time infinite linear;
}

@mixin dashboard-headline-container {
    p {
        @include ff-freight-text-pro-500-reg;
        font-size: 21px;
        padding: 0px 0px 0px 10px;
        display: inline-block;
        vertical-align: super;
        @include responsive(large) {
            font-size: 16px;
        }
        @include responsive(medium) {
            font-size: 14px;
        }
    }
    @include responsive(mobile) {
        display: none;
    }
}

@mixin dashboard-headline-container-mobile {
    display: none;
    @include responsive(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        margin: 10px 0px;
        button {
            @include ff-montserrat-bold;
            width: 100%;
        }
    }
}

@mixin chevron {
    border-style: solid;
    border-width: 0.25px 0.25px 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 4px;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
}

@mixin blue-background-container {
    background: $dark-blue;
    margin: 15px 0;
    padding: 20px;
    border-radius: 4px;
}

@mixin submittable-button {
    button .submitting {
        display: none;
        svg {
            height: 18px;
            @include spin;
        }
    }
    button.is-submitting {
        padding: 8px 8px 6px; // make room for loading indicator
        .submitting {
            display: inline-block;
        }
        .not-submitting {
            display: none;
        }
    }

    button {
        @include responsive(mobile) {
            width: 95% !important;
        }
    }
}

@mixin close-button {
    [mat-dialog-title] {
        position: relative;
    }
    button.close {
        font-size: 20px;
        color: $light-blue;
        text-align: right;
        cursor: pointer;
        padding: 10px;
        margin-left: auto;
        @include ff-montserrat-bold;
        background: none;
        border: none;
        display: block;
        position: absolute;
        right: -10px;
        top: -20px;
        margin: 0;
        width: auto;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: rgba(0, 0, 0, 0.6);
            background: none;
            box-shadow: none;
        }
    }
}

@mixin popover {
    .popover-icon {
        position: absolute;
        top: 2px;
        right: 0;
        background-color: transparent;
        border: 2px solid $light-blue;
        height: 18px;
        width: 18px;
        color: $dark-blue;
        border-radius: 50%;
        text-align: center;
        font-size: 14px;
        &:before {
            content: "i";
            vertical-align: -12%;
            @include ff-freight-text-pro-700-reg;
        }
        &:hover,
        &.active {
            .popover-content {
                display: block;
            }
        }
        .popover-content {
            position: absolute;
            display: none;
            top: 8px;
            left: 40px;
            width: 190px;
            padding: 10px;
            background-color: #fff;
            text-align: left;
            box-shadow: -4px 4px 3px rgba(0, 0, 0, 0.25);
            transform: translateY(-50%);
            @media screen and (max-width: 1080px) {
                top: -90px;
                left: -170px;
                box-sizing: padding-box;
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: -29px;
                width: 0;
                height: 0;
                border-top: 30px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 30px solid transparent;
                border-right: 15px solid #fff;
                transform: translate(0, -50%);
                filter: drop-shadow(-4px 4px 3px rgba(0, 0, 0, 0.25));
                @media screen and (max-width: 1080px) {
                    top: calc(100% - 1px);
                    left: 100%;
                    border-top: 15px solid #fff;
                    border-left: 30px solid transparent;
                    border-bottom: 15px solid transparent;
                    border-right: 30px solid transparent;
                    transform: translate(-100%, 0);
                }
            }
            h6 {
                margin-bottom: 10px;
                font-size: 11px;
                text-transform: uppercase;
                @include ff-montserrat-bold;
            }
        }
    }
}

@mixin popover-password-reqs {
    .popover-icon {
        .popover-content {
            .checkbox {
                margin-top: 7px;
                @include ff-montserrat-reg();
                label {
                    position: relative;
                }
                input[type="checkbox"] {
                    margin-right: 5px;
                    z-index: 3;
                    &:checked:disabled {
                        visibility: hidden;
                        & + span {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 12px;
                            height: 12px;
                            border-radius: 6px;
                            background-color: $active-green;
                            &:before {
                                position: absolute;
                                top: 2px;
                                left: 3px;
                                content: "\2713";
                                font-size: 8px;
                                color: $dark-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin admin-loading-shade {
    .loading-shade {
        position: absolute;
        top: 75.5px;
        left: 0;
        bottom: 56px;
        right: 0;
        background: rgba(0, 0, 0, 0.15);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@mixin admin-table-search {
    .search {
        $padding-x: 25px;
        $padding-y: 10px;
        background: #fff;
        width: calc(100% - $padding-x * 2);
        padding: $padding-y $padding-x 0;
        // height: calc(75.5px - #{$padding-y});
        form {
            width: 100%;
            display: flex;
            align-items: flex-start;
        }
        mat-form-field {
            width: 100%;
            max-width: 400px;
        }
        button[color="basic"] {
            margin-right: auto;
            margin-top: 7px;
        }
        .loading-error {
            padding: 50px 0 100px;
            p {
                color: red;
                @include ff-montserrat-reg;
                text-align: center;
            }
        }
    }
    mat-table.hidden, mat-paginator.hidden {
        display: none;
    }
}

@mixin search-bar() {
    .search-bar {
        cursor: text;
        font-size: 12px;
        padding: 11px 10px;
        margin: 10px;
        border: none;
        border-radius: 4px;
        width: 245px;
        text-transform: uppercase;

        &::placeholder {
            color: $darker-blue;
        }
    }
}

@mixin modal-styles {
    h1[mat-dialog-title] {
        @include ff-montserrat-reg;
        font-size: 21px;
        margin: 0px;
        width: 100%;
        text-align: left;

        @include responsive(mobile) {
            font-size: 18px;
            line-height: inherit;
        }
    }

    p,
    label {
        @include ff-montserrat-reg;
        font-size: 18px;
        margin: 10px 0px;
        display: block;
        text-align: left;

        @include responsive(mobile) {
            font-size: 18px;
        }
    }

    button {
        @include blue-button;
        margin: 25px auto 10px auto;
        @include responsive(mobile) {
            margin: 15px auto !important;
        }
    }

    svg {
        display: block;
        cursor: pointer;
        padding: 0px;
        margin-left: auto;
        margin-right: 0px;
        height: 35px;
        width: 35px;
        stroke: $light-blue;
        flex-shrink: 0;

        &.on-white {
            stroke: $light-blue-on-white;
        }
    }

    textarea {
        @include ff-montserrat-reg;
        font-size: 14px;
        width: 100%;
        height: 125px;
        border: 0;
        border-radius: 4px;
        background: #f1f1f1;
        background-color: #f1f1f1;
        padding: 15px 15px 5px;
        resize: none;
    }

    input {
        @include ff-montserrat-reg;
        font-size: 14px;
        width: 100%;
        border: 0;
        border-radius: 4px;
        background: #f1f1f1;
        background-color: #f1f1f1;
        // padding: 15px 15px 5px;
        resize: none;
    }

    .error {
        p {
            @include ff-montserrat-bold;
            color: $red;
            text-align: center;
            font-size: 12px;
        }
    }
}

@mixin small-container {
    .small-container {
        max-width: 600px;
        padding: 75px;
        margin: 0 auto;

        @include responsive(mobile) {
            max-width: 95%;
            padding: 50px 20px;
        }
    }
}

@mixin home-card {
    @include submittable-button;
    @include small-container;

    ::ng-deep .mat-form-field-label {
        @include ff-montserrat-reg();
    }

    .background-container {
        min-height: 100vh;
        background-image: url("../../../assets/images/oval.svg");
        background-repeat: no-repeat;
        background-position-x: 8vw;
        background-position-y: 0vw;
        background-size: cover;

        @include responsive(mobile) {
            background-image: none;
            height: 100%;
        }
    }

    .btn {
        @include ff-montserrat-bold;
        text-align: center;
        margin: 20px auto 0px auto;
        display: block;
        background: $light-blue-button;
        border: none;
        color: black;
        font-size: 14px;
        border-radius: 4px;
        width: 150px;

        &:hover {
            background: $active-blue-secondary;
            transition: background-color .2s ease-in-out;
        }
    }

    button.is-submitting {
        padding: 8px 8px 0px;
        line-height: 31px;
    }

    mat-form-field {
        width: 100%;
        @include ff-montserrat-bold;

        @include responsive(mobile) {
            margin: 10px 0px;
        }
    }

    mat-card {
        padding: 30px 40px;
    }

    mat-card-title {
        @include ff-freight-text-pro-700-reg();
        @include responsive(mobile) {
            font-size: 21px;
            margin: 20px auto;
            display: block;
            text-align: center;
        }
    }

    .error {
        color: red;
        margin: 15px 0 0;
    }

    button {
        width: 250px !important;

        @include responsive(mobile) {
            width: 100% !important;
        }
    }

    .invalid-btn,
    button[disabled] {
        background: $light-blue-button-disabled;

        &:active {
            background: $light-blue-button-disabled;
        }
        &:hover {
            background: $light-blue-button-disabled;
        }
        .not-submitting {
            opacity: 0.5;
        }
    }

    .logo-container {
        margin: 0px 0px 25px 0px;

        img {
            width: 74px;

            @include responsive(mobile) {
                width: 62px;
                margin: 0 auto;
                display: block;
                text-align: center;
            }
        }
    }

    p.error {
        text-align: center;
    }

    .show-on-mobile {
        display: none;

        .link {
            display: block;
            margin: 15px 0px;
            text-align: center;

            a {
                @include ff-montserrat-bold;
                text-decoration: none;
                font-size: 14px;
                color: white;
            }
        }

        @include responsive(mobile) {
            display: block;
        }
    }
}

@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
