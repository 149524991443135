@use '@angular/material' as mat;

$custom-typography: mat.define-legacy-typography-config(
    $font-family: "'Montserrat', sans-serif",
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500)
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat.define-palette($material-palette);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);
// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme((
    color: (
        primary: $candy-app-primary,
        accent: $candy-app-accent,
        warn: $candy-app-warn,
    ),
    typography: $custom-typography,
    density: 0,
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($candy-app-theme);
.mat-dialog-actions.mat-dialog-actions {
    margin-bottom: 0;
    margin-top: 5px;
    justify-content: space-between;

    @include responsive(mobile) {
        display: flex !important;
    }

    button {
        margin: 0 auto;
    }
}

.mat-calendar-controls.mat-calendar-controls {
    margin: 1% calc(33% / 7 - 16px);
}

mat-cell.mat-cell {
    padding: 0 10px;
    font-size: 14px;
}

[mat-raised-button] {
    line-height: 36px;
}

mat-calendar {
    width: 100%;
    max-width: 250px;
    clear: both;
    display: block;
    position: static;
    background: rgba(60, 73, 81, 0.06);
    border-radius: 4px;
}

mat-form-field {
    width: 100%;
}

.mat-toolbar.mat-primary {
    background: #131e27;
}

.mat-header-cell,
.mat-sort-header-button {
    text-transform: uppercase;
    padding-right: 10px;
    padding-left: 10px;
}

.mat-sort-header-arrow {
    color: #b3e1ea;
}

.mat-flat-button {
    font-size: 10px;
    width: 100px;
    font-weight: bold;
}

.mat-icon.mat-icon {
    width: 20px;
    margin-right: 8px;
}

mat-toolbar .active span.mat-button-wrapper {
    color: #b3e1ea;
    font-weight: bold;
}

.mat-column-action {
    justify-content: space-between;
}

mat-row:nth-child(even) {
    background: #f9f9f9;
}
